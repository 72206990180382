<template>
  <div>
    <flix-calendar-bold :language="$i18n.locale" :calendar="id" :user="$getUserVariables().user.md5_id"></flix-calendar-bold>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: [String, Number]
  },
  data() {
    return {
      variables: false,
      data: false,
      services: false,
      calendar: false
    }
  },
  methods: {
    showCalendar() {
      var newForm = [
        {
          columnName: '12',
          description: this.$t('message.isRequired'),
          id: new Date().getTime() + Math.floor(Math.random(0, 1)) * 1000,
          label: this.$t('message.email'),
          required: true,
          subtype: 'email',
          type: 'text'
        },
        {
          columnName: '12',
          description: this.$t('message.optional'),
          id: new Date().getTime() + Math.floor(Math.random(0, 1)) * 1000,
          label: this.$tc('message.note', 2),
          required: false,
          type: 'textarea'
        }
      ]
      this.data.form = newForm
      this.data.additionalDates = []
      var data = {
        lang: 'de',
        data: {
          data: Object.assign(this.data, {
            user: this.variables.user.md5_id,
            ID: this.id,
            lang: 'de'
          }),
          services: this.services
        },
        saveSettings: 'https://cal.bookingflix.com/vendor/save.js'
      }
      this.calendar = Buffer.from(
        Buffer.from(encodeURI(JSON.stringify(data.data))).toString('utf8')
      ).toString('base64')
      console.log(this.calendar)
    },
    getServices(callback) {
      this.$flix_post({
        data: {
          user: this.variables.user.md5_id,
          ID: this.ID
        },
        url: 'assistent/get_services',
        callback: function (ret) {
          if (ret.data[0]) {
            this.services = ret.data[1]
          }
          callback()
        }.bind(this)
      })
    },
    getData(callback) {
      this.$flix_post({
        data: {
          user: this.variables.user.md5_id,
          ID: this.id
        },
        url: 'assistent/get_data',
        callback: function (ret) {
          if (ret.data[0]) {
            this.data = ret.data[1]
          }
          callback()
        }.bind(this)
      })
    }
  },
  mounted() {
    this.variables = this.$getUserVariables()
    this.getServices(() => {
      this.getData(() => {
        this.showCalendar()
      })
    })
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
